import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { FormAll } from "../Api/FormClient";
import { GetIdentity, GetUser } from "../../../components/Core/AppState/AppState";

export default function useMetaList() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    
      setLoading(true);
      var identity = GetIdentity();
      
      FormAll(identity).then(result => {
        setData(result.data);
        setLoading(false);
      });

  },[]);

  return { data, loading, error }
}
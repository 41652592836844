import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { GetIdentity } from "../../../components/Core/AppState/AppState";
import { FormEdit } from "../Api/FormClient";


export default function useFormEdit() {

  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const formEdit = useCallback(async (formMeta) => {

      setSaving(true);

      const identity = GetIdentity();
      //const model = 
      //{ 
      //  AccountId: identity?.AccountId, 
      //  Name: formValues.name,
      //  Email: formValues.email,
      //  Password: formValues.password
      //};

      FormEdit(identity, formMeta).then(response => {
        const operationResponse = response.data;
        if(!operationResponse.Success)
        {
          toast.error(operationResponse.Message);
          return;
        }
        setSaving(false);
        toast.success("Saved");
      
      }).catch(error => console.log(error));
     
  }, []);

  return { formEdit, saving };
}

import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

//-------------
import { GetIdentity } from "../../../components/Core/AppState/AppState";
import { FormOne } from "../Api/FormClient";
//-------------


export default function useFormGet(itemId) {




  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");


  useEffect(() => {

    if(!itemId)
    {
      var identity = GetIdentity();
      var defaultForm = {
        Id: null,
        AccountId: identity.AccountId,
        Form: null,
        Fields: [],
        RecordState: 0,
      };
      console.log(defaultForm);
      setData(defaultForm);
      return;
    }



    setLoading(true);
    FormOne(identity, itemId).then(response => {
        console.log(response.data);
        setData(response.data);
        setLoading(false);
    }).catch(error => console.log(error));


  }, [itemId]);


  return { data, setData, loading, error }
}

import { useState } from 'react'
import * as React from 'react';
import { useForm } from "react-hook-form";


import DottedSpinner from "../../components/Core/DottedSpinner/DottedSpinner";
import TemplateSelector from './Components/TemplateSelector';
import ElementNewView from './Views/ElementNewView';

import useFormGet from './Hooks/useFormGet';
import useFormEdit from './Hooks/useFormEdit';
import { v4 as uuidv4 } from 'uuid';

export default function Editor() {

  //-----------
  const location = window.location;
  const itemId = location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length) ;
  //-----------
  const { data, setData, loading } = useFormGet(itemId);
  const { formEdit, saving } = useFormEdit();
  //-----------
  const [newElementOpen, setNewElementOpen] = useState(false);



  const add = (type) => 
  {
      const newRow = { Id: uuidv4(), Text: "New Element Text", Type: type };
      // edit form
      const formModel = data.Form;
      const list = [ ...formModel.Content, newRow ];
      const newForm = { ...formModel, Content: list };
      const newData = { ...data, Form: newForm};
      setData(newData)
  };

  const save = async () => 
  {
      await formEdit(data);
  };


  const itemEdit = (row, action, payload) => 
  {

    if(action === "delete")
    {
      const formModel = data.Form;
      const list = formModel.Content.filter(item => item.Id !== row.Id);
      const newForm = { ...formModel, Content: list };
      const newData = { ...data, Form: newForm};
      setData(newData)
    }

    if(action === "edit")
    {


      setNewElementOpen(true);


    }


  };




  return (<>{ !data || loading ? <>Loading</> : 
  <>
  <button onClick={() => save()}>Save</button>

  <ElementNewView open={newElementOpen} setOpen={setNewElementOpen} createElement={add} />


  <div className="isolate bg-white">
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data.Form.Content.map((row) => (
          <TemplateSelector row={row} itemEdit={itemEdit} />
        ))}
      </ul>
      </div>
      <button onClick={()=> setNewElementOpen(true)}>+ Add Row</button>
  </div>
  
  </>}</>);
}

import { useCallback, useState } from "react";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { SetUser } from "../../../Core/AppState/AppState"; 
import Config from "../../../../configurations/config";

export default function useResetPassword(formValues) {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);



  const resetPassword = useCallback(async () => {
    try {
      setLoading(true);

      console.log(formValues);

      const requestBody = {
        Action: "auth/resetpassword",
        Payload: { TransactionId: formValues.transationId, Password: formValues.password, ConfirmPassword: formValues.confirmpassword },
      };

      const response = await axios.post(Config.ServerUrl, requestBody);
      const operationResponse = response.data;
      if(!operationResponse.Success)
      {
        toast.error(operationResponse.Message);
        return;
      }

      toast.success(operationResponse.Message);
      
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [formValues]);

  return { resetPassword, loading };
}
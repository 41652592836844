import { useState } from 'react'
import * as React from 'react';
import { useForm } from "react-hook-form";

import DottedSpinner from "../../components/Core/DottedSpinner/DottedSpinner";
import TemplateSelector from './Components/TemplateSelector';


import useFormGet from './Hooks/useFormGet';
import useFormEdit from './Hooks/useFormEdit';

import { useNavigate } from "react-router-dom";
import useAuditEdit from './Hooks/useAuditEdit';

export default function Form() {

  const navigate = useNavigate();
  //-----------
  const location = window.location;
  const itemId = location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length) ;
  //-----------
  const { data, setData, loading } = useFormGet(itemId);
  const { formEdit, saving } = useFormEdit();
  const { auditEdit } = useAuditEdit();





  const complete = async () => 
  {
    var auditModel = { ...data, Id: null };
    
    await auditEdit(auditModel);

  };




  return (<>{ !data || loading ? <>Loading</> : 
  <>

  <a  
  href={`/editor/${itemId}`}
  className="rounded-md bg-indigo-600 my-3 mb-3 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
  Editor
  </a>



  <div className="isolate bg-white">
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data.Form.Content.map((row) => (
          <TemplateSelector row={row} />
        ))}
      </ul>
      </div>
  </div>



  <button 
  onClick={() => complete()}
  className="rounded-md bg-indigo-600 my-3 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
  Complete
  </button>

  


  </>}</>);

}
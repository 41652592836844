import { loadStripe } from '@stripe/stripe-js';

//----------------

const NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY='pk_test_my_key'
const NEXT_PUBLIC_STRIPE_PRICE_ID='price_my_id'

//----------------

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_test_3EGmzvcMsuj20B0WJOE4d8jR");
  }
  return stripePromise;
};

export default getStripe;
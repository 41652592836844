
import { useState } from 'react'
import * as React from 'react';
import { useForm } from "react-hook-form";



export default function PassFailRow() {


  return (
    <div>
    DEMO TEXT TEST
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
        Pass
      </button>
      <button
        type="button"
        className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
        Fail
      </button>
    </span>
    
    </div>
  )
}
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

export default function ElementNewView({ open, setOpen, createElement }) {

  
  //const [open, setOpen] = useState(true)
  const itemClick = (type) => 
  {
      setOpen(false);
      createElement(type);
  };


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="mt-5 sm:mt-6">
                    <button
                    type="button"
                    className="inline-flex mt-2 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => itemClick("header")}>
                        Header
                    </button>
                    
                    <button
                    type="button"
                    className="inline-flex mt-2 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => itemClick("passfailna")}>
                        Pass/Fail/NA
                    </button>

                    <button
                    type="button"
                    className="inline-flex mt-2 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => itemClick("passfail")}>
                        Pass/Fail
                    </button>

                    <button
                    type="button"
                    className="inline-flex mt-2 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => itemClick("signature")}>
                        Signature
                    </button>

                    <button
                    type="button"
                    className="inline-flex mt-2 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => itemClick("pictures")}>
                        Pictures
                    </button>





                    <div>
                    <label htmlFor="text" className="block text-sm font-medium text-gray-700">
                      Text
                    </label>
                    <div className="mt-1">
                      <input
                      type="text"
                      name="text"
                      id="text"
                      autoComplete="text-name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    </div>
     



                    <div>
                    <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                    Type
                    </label>
                    <div className="mt-1">
                    <select
                      id="type"
                      name="type"
                      autoComplete="type-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                      <option>Header</option>
                      <option>Yes/No</option>
                      <option>Pass/Fail</option>
                      <option>Pictures</option>
                      <option>Signature</option>
                      <option>Entry</option>
                    </select>
                    </div>
                    </div>




                    <div class="flex flex-row">

                      <button
                        type="button"
                        className="basis-1/2 mr-1 mt-2 w-full justify-center rounded-md 
                        bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm 
                        hover:bg-red-500 focus-visible:outline focus-visible:outline-2 
                        focus-visible:outline-offset-2 focus-visible:outline-red-600">
                        Delete
                      </button>

                      <button
                        type="button"
                        className="basis-1/2 ml-1 mt-2 w-full justify-center rounded-md 
                        bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm 
                        hover:bg-green-500 focus-visible:outline focus-visible:outline-2 
                        focus-visible:outline-offset-2 focus-visible:outline-green-600">
                        Save
                      </button>

                    </div>


     


                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { useCallback, useState } from "react";

import { ReactSession }  from 'react-client-session';


const user_key = "digiform_user"


export function GetUser() 
{
    const json = ReactSession.get(user_key); 
    if(!json)
        return null;

    var currentUser = JSON.parse(json);
    return currentUser;
}

export function GetIdentity() 
{
    const json = ReactSession.get(user_key); 
    if(!json)
        return null;
    var currentUser = JSON.parse(json);
    return { UserId: currentUser.Id, AccountId: currentUser.AccountId };
}

export function SetUser(user) 
{
    ReactSession.set(user_key, JSON.stringify(user));
}

export function Reset() 
{
    ReactSession.set(user_key, null);
}
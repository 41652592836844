
import { useState } from 'react'
import * as React from 'react';
import { useForm } from "react-hook-form";
import SignatureCanvas from 'react-signature-canvas'



export default function SignatureRow({ row, action }) {




  const itemDelete = async () => 
  {
    action(row, "delete", "123");
  };



  return (
    <div >
      Signature
      <span className="isolate inline-flex rounded-md shadow-sm">
        <SignatureCanvas penColor='green' canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
      </span>
      <button onClick={() => itemDelete()}>Delete</button>
    </div>
  )
}
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { UserAll } from "../Api/UserClient";
import { GetIdentity, GetUser } from "../../../components/Core/AppState/AppState";

export default function useUserList() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    
      setLoading(true);
      var identity = GetIdentity();
      console.log(identity);
      UserAll(identity).then(result => {
        console.log(result);
        setData(result.data);
        setLoading(false);
      });


  },[])

  return { data, loading, error }
}
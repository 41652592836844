import React, { useState } from 'react';
import {  Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineSetting, AiOutlineDownSquare, AiOutlineTeam, AiOutlineDashboard, AiOutlineForm, AiOutlineLogout, AiOutlineEdit, AiOutlineCreditCard } from 'react-icons/ai';


import { Reset } from '../Core/AppState/AppState';


import DashbaordApp from '../../apps/DashboardApp/DashboardApp';
import DemoApp from '../../apps/DemoApp/DemoApp';


// billing
import Pricing from '../../apps/BillingApp/Pricing';
import ThankYou from '../../apps/BillingApp/ThankYou';

// audit
import AuditList from '../../apps/AuditApp/AuditList';
import AuditEdit from '../../apps/AuditApp/AuditEdit';

// form
import Editor from '../../apps/Form/Editor';
import Form from "../../apps/Form/Form";
import FormMetaList from '../../apps/FormMeta/FormMetaList';
import FormMetaEdit from '../../apps/FormMeta/FormMetaEdit';

// user
import UserList from "../../apps/TeamApp/UserList";
import UserEdit from "../../apps/TeamApp/UserEdit";

// location
import LocationList from '../../apps/LocationApp/LocationList';
import LocationEdit from '../../apps/LocationApp/LocationEdit';

// account
import Account from '../../apps/AccountApp/Account';
import Final from '../../apps/Form/Final';
import Audit from '../../apps/AuditApp/Audit';




export default function ContentLayout() {


   const navigate = useNavigate();
   const onLogOut = () => {
         // eslint-disable-next-line no-restricted-globals
         var confirmed = confirm("Please confirm to log out?");
         if(!confirmed)
            return;

         Reset();
         navigate('/auth/login');
    };


  return (<>
 <aside id="default-sidebar" class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
    <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
       <ul class="space-y-2 font-medium">

          <li>
             <a href="/dashboard" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <AiOutlineDashboard />
                <span class="ml-3">Dashboard</span>
             </a>
          </li>


          <li>
             <a href="/forms" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <AiOutlineForm />
                <span class="flex-1 ml-3 whitespace-nowrap">Forms</span>
             </a>
          </li>


          <li>
             <a href="/audits" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <AiOutlineDownSquare />
                <span class="flex-1 ml-3 whitespace-nowrap">Audits</span>
             </a>
          </li>


          <li>
             <a href="/account" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <AiOutlineSetting />
                <span class="flex-1 ml-3 whitespace-nowrap">Account</span>
             </a>
          </li>


          <li>
             <a href="/pricing" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <AiOutlineCreditCard />
                <span class="flex-1 ml-3 whitespace-nowrap">Billing</span>
             </a>
          </li>


          <li>
             <a href="/users" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <AiOutlineTeam />
                <span class="flex-1 ml-3 whitespace-nowrap">Team</span>
             </a>
          </li>


          <li>
             <a href="/locations" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <AiOutlineTeam />
                <span class="flex-1 ml-3 whitespace-nowrap">Locations</span>
             </a>
          </li>


          <li>
             <a href="#" onClick={onLogOut} class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <AiOutlineLogout />
                <span class="flex-1 ml-3 whitespace-nowrap">Log Out</span>
             </a>
          </li>
       </ul>
    </div>
 </aside>
 
 <div class="p-4 sm:ml-64">

    <Routes className="h-100">

          <Route path="/" element={<DashbaordApp />} />
          <Route path="/dashboard" element={<DashbaordApp />} />
          <Route path="/reports" element={<DemoApp />} />


          {/* forms */}
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/thankyou" element={<ThankYou />} />
          

          {/* forms */}
          <Route path="/forms" element={<FormMetaList />} />
          <Route path="/forms/create" element={<FormMetaEdit />} />
          <Route path="/forms/:itemId" element={<Form />} />
          <Route path="/final/:itemId" element={<Final />} />
          <Route path="/editor/:itemId" element={<Editor />} />


          {/* audits */}
          <Route path="/audits" element={<AuditList />} />
          <Route path="/audits/create" element={<AuditEdit type="create" />} />
          <Route path="/audits/edit/:itemId" element={<AuditEdit type="edit" />} />
          <Route path="/audits/:itemId" element={<Audit />} />

          {/* account */}
          <Route path="/account" element={<Account />} />


          {/* users */}
          <Route path="/users" element={<UserList />} />
          <Route path="/users/create" element={<UserEdit type="create" />} />
          <Route path="/users/edit/:userId" element={<UserEdit type="edit" />} />


          {/* locations */}
          <Route path="/locations" element={<LocationList />} />
          <Route path="/locations/create" element={<LocationEdit type="create" />} />
          <Route path="/locations/edit/:locationId" element={<LocationEdit type="edit" />} />

    </Routes>

 </div>


 </>);
};


import { useState } from 'react'
import * as React from 'react';
import { useForm } from "react-hook-form";

import DottedSpinner from '../../components/Core/DottedSpinner/DottedSpinner';
import useAuditEdit from './Hooks/useAuditEdit';

import { useParams } from 'react-router-dom';
import useAuditOne from './Hooks/useAuditOne';
import TemplateSelector from '../Form/Components/TemplateSelector';


export default function Audit() {


  //-----------
  const { itemId } = useParams();
  const { data, loading } = useAuditOne(itemId);
  //-----------

  return (
    <div className="isolate bg-white px-6 lg:px-8">
      {!data || loading ? <DottedSpinner /> : 

        <div className="isolate bg-white px-6 lg:px-8">
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
            {data.Form.Content.map((row) => (
                <TemplateSelector row={row} />
            ))}
            </ul>
        </div>
        </div>

      }
    </div>
  
  )
}

import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";


import Login from "./Login/Login";
import Register from "./Register/Register";
import ResetPassword from "./ResetPassword/ResetPassword";
import ForgotPassword from "./ResetPassword/ForgotPassword";

export default function Auth() {
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname === "/") navigate("/projects");

  return (

        <Routes>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="resetpassword" element={<ResetPassword />} />
              <Route path="forgotpassword" element={<ForgotPassword />} />
        </Routes>


  );
}
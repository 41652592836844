
import { useState } from 'react'
import * as React from 'react';
import { useForm } from "react-hook-form";

import DottedSpinner from '../../components/Core/DottedSpinner/DottedSpinner';
import useMetaEdit from './Hooks/useMetaEdit';

export default function FormMetaEdit() {


  //------------
  const location = window.location;
  const objectId = location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length) ;
  
  //-----------
  const form = useForm();
  const { register, handleSubmit, watch, formState } = form;
  const { errors } = formState;
  const formValues = watch();
  const { objectEdit, loading } = useMetaEdit(formValues);
  //-----------



  return (
    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">

      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">New Form</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
        </p>
      </div>
      <form onSubmit={handleSubmit(objectEdit)} method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
 
          <div className="sm:col-span-2">
            <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
              Name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="text"
                id="text"
                {...register("text", { required: "Field is required" })}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="description" className="block text-sm font-semibold leading-6 text-gray-900">
              Description
            </label>
            <div className="mt-2.5">
              <textarea
                name="description"
                id="description"
                rows={4}
                {...register("description", { required: "Field is required" })}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={''}
              />
            </div>
          </div>
    
        </div>
        <div className="mt-10">
          <button type="submit" className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            {loading ? <DottedSpinner /> : <span>Save</span>}
          </button>
        </div>
      </form>
    </div>
  )
}
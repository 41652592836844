
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'
import Header from './Components/Header'
import AuditRow from './Components/AuditRow'
import useAuditList from './Hooks/useAuditList';
import Spinner from "../../components/Core/Spinner/Spinner";


export default function AuditList() {

  const { data, loading } = useAuditList();
  return (
    <>
    <Header />
    {loading ? <Spinner />:
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data?.map((item) => (
          <AuditRow meta={item} />
        ))}
      </ul>
    </div>
    }
    </>
  )
}
import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { GetIdentity } from "../../../components/Core/AppState/AppState";
import { AuditEdit } from "../Api/AuditClient";


export default function useAuditEdit() {

  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const auditEdit = useCallback(async (auditMeta) => {

      setSaving(true);

      const identity = GetIdentity();

      AuditEdit(identity, auditMeta).then(response => {
        const operationResponse = response.data;
        if(!operationResponse.Success)
        {
          toast.error(operationResponse.Message);
          return;
        }
        setSaving(false);
        toast.success("Saved");
      
      }).catch(error => console.log(error));
     
  }, []);

  return { auditEdit, saving };
}
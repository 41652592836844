
import { useState } from 'react'
import * as React from 'react';
import { useForm } from "react-hook-form";


import PassFailRow from './PassFailRow';
import PassFailNARow from './PassFailNARow';
import HeaderRow from './HeaderRow';
import FakeRow from './FakeRow';
import SignatureRow from './SignatureRow';
import PicturesRow from './PicturesRow';

export default function TemplateSelector({ row, itemEdit }) {

    var rowType = row?.Type;
    switch(rowType) {
  
        case "passfailna":
          return (<PassFailNARow row={row} action={itemEdit} />);

        case "passfail":
          return (<PassFailRow row={row} action={itemEdit} />);
          
        case "header":
          return (<HeaderRow row={row} action={itemEdit} />);

        case "signature":
          return (<SignatureRow row={row} action={itemEdit} />);

        case "pictures":
          return (<PicturesRow row={row} action={itemEdit} />);
        
        default:
          return (<FakeRow row={row} action={itemEdit} />);
        
      }
      
}
import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { GetIdentity } from "../../../components/Core/AppState/AppState";
import { UserEdit } from "../Api/UserClient";


export default function useUserEdit(formValues) {

  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const userEdit = useCallback(async () => {

    setSaving(true);

      const identity = GetIdentity();
      const model = 
      { 
        AccountId: identity?.AccountId, 
        Name: formValues.name,
        Email: formValues.email,
        Password: formValues.password
      };

      UserEdit(identity, model).then(response => {
        const operationResponse = response.data;
        if(!operationResponse.Success)
        {
          toast.error(operationResponse.Message);
          return;
        }
        setSaving(false);
        toast.success("Saved");
        navigate(`/users`);
      }).catch(error => console.log(error));
     
  }, [formValues, navigate]);

  return { userEdit, saving };
}

import { useState } from 'react'
import * as React from 'react';
import { useForm } from "react-hook-form";



export default function PicturesRow() {

    var imageUrl = "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80";

  return (
    <div>
    Pictures

    <ul role="list" className="mt-2 flex gap-x-3">
        <li>
            <a href="#" className="text-gray-400 hover:text-gray-500">
                <img className="aspect-[3/2] w-full rounded-2xl object-cover" src={imageUrl} alt="" />   
            </a>
        </li>

        <li>
            <a href="#" className="text-gray-400 hover:text-gray-500">
                <img className="aspect-[3/2] w-full rounded-2xl object-cover" src={imageUrl} alt="" />   
            </a>
        </li>

        <li>
            <a href="#" className="text-gray-400 hover:text-gray-500">
                <img className="aspect-[3/2] w-full rounded-2xl object-cover" src={imageUrl} alt="" />   
            </a>
        </li>

        <li>
            <a href="#" className="text-gray-400 hover:text-gray-500">
                <img className="aspect-[3/2] w-full rounded-2xl object-cover" src={imageUrl} alt="" />   
            </a>
        </li>

        <li>
            <a href="#" className="text-gray-400 hover:text-gray-500">
                <img className="aspect-[3/2] w-full rounded-2xl object-cover" src={imageUrl} alt="" />   
            </a>
        </li>
    </ul>
    
    </div>
  )
}

import { useState } from 'react'
import * as React from 'react';
import { useForm } from "react-hook-form";

import DottedSpinner from "../../components/Core/DottedSpinner/DottedSpinner";
import TemplateSelector from './Components/TemplateSelector';


import useFormGet from './Hooks/useFormGet';
import useFormEdit from './Hooks/useFormEdit';


export default function Final() {

  const location = window.location;
  const itemId = location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length) ;
  //-----------

  return (<>Final</>);
  
}


import { useState } from 'react'
import * as React from 'react';
import { useForm } from "react-hook-form";

import DottedSpinner from '../../components/Core/DottedSpinner/DottedSpinner';
import useUserEdit from './Hooks/useUserEdit';

import { useParams } from 'react-router-dom';
import useUserOne from './Hooks/useUserOne';


export default function UserEdit({ type }) {


  //------------

  const { userId } = useParams();
  const { data, loading } = useUserOne(userId);
  //const location = window.location;
  //const objectId = location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length) ;
  
  //-----------
  const form = useForm();
  const { register, handleSubmit, watch, formState } = form;
  const { errors } = formState;
  const formValues = watch();
  const { userEdit, saving } = useUserEdit(formValues);
  //-----------




  return (
    <div className="isolate bg-white px-6 lg:px-8">
      {!data || loading ? <DottedSpinner /> : 

      <form onSubmit={handleSubmit(userEdit)} method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
 
          <div className="sm:col-span-2">
            <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
              Name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="name"
                id="name"
                defaultValue={data?.Name}
                {...register("name", { required: "Field is required" })}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="email"
                id="email"
                defaultValue={data?.Email}
                {...register("email", { required: "Field is required" })}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="password" className="block text-sm font-semibold leading-6 text-gray-900">
              Password
            </label>
            <div className="mt-2.5">
              <input
                type="password"
                name="password"
                id="password"
                defaultValue={data?.Password}
                {...register("password", { required: "Field is required" })}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div className="mt-10">
          <button type="submit" className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            {loading ? <DottedSpinner /> : <span>Save</span>}
          </button>
        </div>

      </form>
      }



    </div>
  
  )
}


import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";



import Auth from "./components/Auth/Auth";
import ContentLayout from "./components/Content/ContentLayout";
import { GetUser } from "./components/Core/AppState/AppState";



function App() {

  const navigate = useNavigate();


  useEffect(() => {

    var relativeUrl = window.location.pathname;
    var user = GetUser();
    const noUser = user == null && !relativeUrl.includes("/auth/")

    // Checking if user is not loggedIn
    if (noUser) {
      navigate("/auth/login");
    } 

  }, [navigate]);

  //const [isLoggedIn, setisLoggedIn] = useState(false);

  
 





  return (
    <div>
         <div><Toaster/></div>
      <Routes>
        <Route path="/auth/*" element={<Auth />} />
        <Route path="/*" element={<ContentLayout />} /> 
      </Routes>
    </div>
  );
}

export default App;

import axios from 'axios';
import Config from '../../../configurations/config';



export function FormEdit(identity, model) 
{
    const apiPayload = { Identity: identity, Model: model };
    return Exec("forms/meta/edit", apiPayload);
}


export function FormOne(identity, itemId) 
{
    const apiPayload = { Identity: identity, ItemId: itemId };
    return Exec("forms/meta/one", apiPayload);
}


export function FormAll(identity) 
{
    const apiPayload = { Identity: identity };
    return Exec("forms/meta/all", apiPayload);
}


function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.ServerUrl, requestBody);
}
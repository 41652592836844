import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { UserOne } from "../Api/UserClient";
import { GetIdentity, GetUser } from "../../../components/Core/AppState/AppState";

export default function useUserOne(itemId) {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    
      if(itemId == null)
        return;

      setLoading(true);
      var identity = GetIdentity();
      console.log(identity);
      UserOne(identity, itemId).then(result => {
        console.log(result);
        setData(result.data);
        setLoading(false);
      });

  },[itemId])

  return { data, loading, error }
}
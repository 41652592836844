import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'

import userIcon from "../Assets/user.png";

export default function UserRow({ meta }) {
  return (
    <>
      <a href={`users/edit/${meta.Id}`} className="block hover:bg-gray-50">
        <li key={meta.Id}>
        
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="flex-shrink-0">
                    <img className="h-12 w-12" src={userIcon} alt="" />
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">

                    <div>
                      <p className="truncate text-sm font-medium text-indigo-600">{meta.Name}</p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <EnvelopeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <span className="truncate">{meta.Email}</span>
                      </p>
                    </div>

                    <div className="hidden md:block">
                      <div>
                      </div>
                    </div>

                  </div>
                </div>
                <div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
          </li>
      </a>



    
    </>
  )
}
import React from "react";
import { StyledContainer } from "./styles";

export default function DottedSpinner() {
  return (
    <StyledContainer className="d-flex justify-content-center align-items-center">
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </StyledContainer>
  );
}
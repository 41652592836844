import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { AccountOne } from "../Api/AccountClient";
import { GetIdentity, GetUser } from "../../../components/Core/AppState/AppState";

export default function useAccountOne() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  
  useEffect(() => {
      var identity = GetIdentity();

      if(!identity)
        return;

      setLoading(true);
      AccountOne(identity, identity.AccountId).then(result => {

        console.log(result);
        setData(result.data);

        setLoading(false);
      });

  },[])

  return { data, loading, error }
}
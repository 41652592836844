import { CheckCircleIcon } from '@heroicons/react/20/solid'


export default function ThankYou() {


  return (
    <div className="bg-white">
        THANK YOU
    </div>
  )
}
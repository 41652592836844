
import { useCallback, useState } from 'react';



export default function DashbaordApp() {

 

  return (<div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">

  <div class="grid grid-cols-3 gap-4 mb-4">


     <div class="flex bg-sky-600 items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
        <p class="text-2xl text-white">95</p>
        <p class="text-xl text-slate-200">TOTAL</p>
     </div>


     <div class="flex bg-sky-600 items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
        <p class="text-2xl text-white">10</p>
        <p class="text-xl text-slate-200">CURRENT MONTH</p>
     </div>


     <div class="flex bg-sky-600 items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
        <p class="text-2xl text-white">10</p>
        <p class="text-xl text-slate-200">CURRENT WEEK</p>
     </div>

  </div>

  </div>);

}
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'

import itemIcon from "../Assets/audit.png";

export default function AuditRow({ meta }) {
  return (
    <>


        <li key={meta.Id}>
            <a href={`audits/${meta.Id}`} className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="flex-shrink-0">
                    <img className="h-12 w-12" src={itemIcon} alt="" />
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="truncate text-sm font-medium text-indigo-600">{meta.Text}</p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <span className="truncate">Audit</span>
                      </p>
                    </div>
                    <div className="hidden md:block">
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </a>
          </li>


    </>
  )
}
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'
import Header from './Components/Header'
import FormMetaRow from './Components/FormMetaRow'
import useMetaList from './Hooks/useMetaList';


export default function FormMetaList() {

  const { data, loading } = useMetaList();

  return (
    <>
    <Header />
    {loading ? <>Loading</>:
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data?.map((meta) => (
          <FormMetaRow meta={meta} />
        ))}
      </ul>
    </div>
    }
    </>
  )
}
import axios from 'axios';
import Config from '../../../configurations/config';


export function AuditEdit(identity, model) 
{
    const apiPayload = { Identity: identity, Model: model };
    return Exec("audits/edit", apiPayload);
}

export function AuditOne(identity, itemId) 
{
    const apiPayload = { Identity: identity, ItemId: itemId };
    return Exec("audits/one", apiPayload);
}

export function AuditAll(identity) 
{
    return Exec("audits/all", identity);
}

function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.ServerUrl, requestBody);
}
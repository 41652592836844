import axios from 'axios';
import Config from '../../../configurations/config';

export function UserEdit(identity, model) 
{
    const apiPayload = { Identity: identity, Model: model };
    return Exec("users/edit", apiPayload);
}

export function UserOne(identity, itemId) 
{
    const apiPayload = { Identity: identity, ItemId: itemId };
    return Exec("users/one", apiPayload);
}

export function UserAll(identity) 
{
    return Exec("users/all", identity);
}

function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.ServerUrl, requestBody);
}

import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'
import Header from './Components/Header'
import LocationRow from './Components/LocationRow'
import useLocationList from './Hooks/useLocationList';
import Spinner from "../../components/Core/Spinner/Spinner";



export default function LocationList() {

  const { data, loading } = useLocationList();

  return (
    <>
    <Header />
    {loading ? <Spinner />:
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data?.map((item) => (
          <LocationRow meta={item} />
        ))}
      </ul>
    </div>
    }
    </>
  )
}
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'
import Header from './Components/Header'
import UserRow from './Components/UserRow'
import useUserList from './Hooks/useUserList';

import Spinner from "../../components/Core/Spinner/Spinner";

export default function UserList() {

  const { data, loading } = useUserList();

  return (
    <>
    <Header />
    {loading ? <Spinner />:
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data?.map((item) => (
          <UserRow meta={item} />
        ))}
      </ul>
    </div>
    }
    </>
  )
}